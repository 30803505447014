import React from "react";
import ScheduleItem from "./ScheduleItem";
import ProvderLogo from "../assets/images/clockcaster-logo-white-small.png";

const Sidebar = ({ lineupData }) => {
  // Only render the sidebar if lineupData exists and has at least one item
  if (!lineupData || lineupData.length === 0) {
    return null;
  }

  return (
    <aside className="w-full md:w-96 bg-gray-900 p-4 md:h-[calc(100vh-3rem)] max-h-[calc(100vh-3rem)] overflow-y-scroll no-scrollbar text-white sticky top-0">
      <h1 className="text-center text-3xl">Event Lineup</h1>
      <a
        href="https://clockcaster.com/e/59"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer"
      >
        <p className="flex items-center justify-center gap-2 text-sm text-gray-300 mb-4">
          Powered By:{" "}
          <img className="h-5 object-contain" src={ProvderLogo} alt="" />
        </p>
      </a>
      <ul id="schedule-list" className="divide-y divide-gray-800">
        {lineupData.map((item, index) => (
          <ScheduleItem key={index} item={item} />
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
