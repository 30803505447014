import { useParams } from "react-router-dom";
import eventLogo from "../assets/images/lbra_Logo.png";
import Sidebar from "../components/Sidebar";
import VideoPlayer from "../components/VideoPlayer";
import { supabase } from "../utils/supabase";
import { useEffect, useState } from "react";

function Stream() {
  const { cdnId } = useParams();
  const defaultCdnId = "fbe7358f-3c65-484f-8131-ac78bd29c93b"; // Replace with your actual default CDN ID
  const cdnIdToUse = cdnId || defaultCdnId; // Use the default if cdnId is not provided

  const [videoInfo, setVideoInfo] = useState({
    title: "Loading...",
    description: "Please wait while we fetch the video details.",
    date: "",
    eventName: "",
    venue: "",
    event_results: { schedule: [] }, // Initialize event_results.schedule as an empty array
  });

  useEffect(() => {
    async function fetchVideoInfo() {
      try {
        const { data, error } = await supabase
          .from("catalog")
          .select(`name, date, event_results, event(name, venue)`)
          .eq("cdn_id", cdnIdToUse)
          .single();

        if (error) {
          console.error("Error fetching video details:", error.message);
          setVideoInfo({
            title: "Error",
            description: "Not Found",
            date: "",
            eventName: "",
            venue: "",
            event_results: { schedule: [] },
          });
          return;
        }

        if (data) {
          setVideoInfo({
            title: data.name || "Untitled",
            description: `Event: ${data.event?.name || "Unknown"} - ${
              data.event?.venue || "Unknown Venue"
            }`,
            date: new Date(data.date).toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              timeZone: "UTC",
            }),
            eventName: data.event?.name || "Unknown Event",
            venue: data.event?.venue || "Unknown Venue",
            event_results: data.event_results || { schedule: [] },
          });
        }
      } catch (err) {
        console.error("Unexpected error:", err);
        setVideoInfo({
          title: "Error",
          description: "Something went wrong while fetching video details.",
          date: "",
          eventName: "",
          venue: "",
          event_results: { schedule: [] },
        });
      }
    }

    fetchVideoInfo();
  }, [cdnIdToUse]);

  return (
    <div className="bg-gray-950">
      <div className="h-12"></div>
      <main className="flex flex-col md:flex-row flex-1">
        <div className="flex-1 bg-gray-950 flex flex-col">
          <VideoPlayer cdn_id={cdnIdToUse} />
          <div className="w-full p-3 text-white">
            <div className="flex gap-4 items-center ml-2">
              <div className="w-14 aspect-square rounded-sm overflow-hidden hidden">
                <img
                  alt="logo"
                  src={eventLogo}
                  className="w-full h-full object-cover"
                />
              </div>
              <div>
                <h1 className="text-2xl font-light">{videoInfo.title}</h1>
                <p className="font-thin">
                  {videoInfo.date} | {videoInfo.venue}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Sidebar lineupData={videoInfo.event_results.schedule} />
      </main>
    </div>
  );
}

export default Stream;
