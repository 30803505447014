import React from "react";
import BackgroundImage from "../assets/images/HOCR_Hero.JPG";

const Hero = () => {
  return (
    <div className="relative">
      {/* Background Image */}
      <img
        src={BackgroundImage}
        alt="two 8+ rowing down the banks of the charles river towards a bridge"
        className="w-full min-h-[300px] max-h-[600px] object-cover"
      />
      <div className="absolute w-full z-20 flex items-center justify-between bottom-7 px-2 lg:hidden text-white">
        <div>
          <h3 className="font-bold text-3xl">Head of the Charles</h3>
          <p className="font-light">Relive the largest regatta in the U.S.</p>
        </div>
        <a href="/stream/f2659bab-fcc2-496f-a62a-7d93bcfe2f8c">
          <button className="border-white border-2 rounded-full backdrop-blur-sm p-3 hover:bg-white hover:text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-play"
              viewBox="0 0 16 16"
            >
              <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
            </svg>
          </button>
        </a>
      </div>

      {/* Overlay Content */}
      <div className="hidden lg:block absolute top-1/2 right-2 text-end -translate-y-1/4 shadow-md rounded-md  p-4 bg-blend-overlay text-white">
        <div className=" backdrop-blur-sm px-2 rounded-xl">
          <h3 className=" md:text-6xl font-black text-3xl ">
            Head of the Charles
          </h3>
          <p className="md:text-2xl">Relive the largest regatta in the U.S.</p>
        </div>
        {/* Action Buttons */}
        <div className="flex float-end gap-1 mt-3 text-gray-950 lg:text-white">
          <a href="/stream/f2659bab-fcc2-496f-a62a-7d93bcfe2f8c">
            <button className="bg-slate-100 hover:bg-slate-300 lg:bg-gray-950/70 lg:hover:bg-gray-950/90 px-3 py-2 rounded-md font-bold">
              Watch Now
            </button>
          </a>
          <i className="bi bi-info-circle"></i>
          <button className="hidden bg-slate-100 hover:bg-slate-300 lg:bg-gray-950/70 lg:hover:bg-gray-950/90 w-10 h-10 rounded-full flex items-center font-bold justify-center">
            +
          </button>
        </div>
      </div>

      {/* Gradient Overlay */}
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-transparent to-gray-950 h-32"></div>
    </div>
  );
};

export default Hero;
