import { supabase } from "../utils/supabase";
import { useState } from "react";

function TestSupabase() {
  const [query, setQuery] = useState(`
        *,
        schedule (
          *,
          race (
            *
            ),
          category (
 *
          ),
          result(
                *,
                    competitor(
                    *
                )
            )
        )
  `);
  const [queryResult, setQueryResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  async function testQuery() {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .schema("timing")
        .from("event")
        .select(query);

      if (error) {
        setError(error.message);
      } else {
        setQueryResult(data);
      }
    } catch (err) {
      setError("Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="bg-gray-900 min-h-screen text-white p-4 mt-10">
      <h1 className="text-3xl mb-4">Supabase Query Tester</h1>
      <textarea
        className="w-full bg-gray-800 text-white p-2 rounded mb-4"
        rows="6"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Type your Supabase query here..."
      ></textarea>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={testQuery}
        disabled={loading}
      >
        {loading ? "Loading..." : "Run Query"}
      </button>
      <div className="mt-4">
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
        {queryResult && (
          <pre className="bg-gray-800 p-4 rounded overflow-auto">
            {JSON.stringify(queryResult, null, 2)}
          </pre>
        )}
      </div>
    </div>
  );
}

export default TestSupabase;
