import React, { useState } from "react";
import DetailsTable from "./DetailsTable";

const ScheduleItem = ({ item }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails(!showDetails);

  return (
    <li className="py-5">
      <div>
        <div className="flex justify-between items-center">
          <p className="font-semibold">
            {item.race.race_num}: {item.time} - {item.category.name}
          </p>
          <button
            className="toggle-details px-2 focus:outline-none"
            onClick={toggleDetails}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className={`caret transform transition-transform duration-200 ${
                showDetails ? "rotate-180" : ""
              }`}
              viewBox="0 0 16 16"
            >
              <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
            </svg>
          </button>
        </div>
      </div>
      {showDetails && <DetailsTable results={item.results} />}
      <div className="flex gap-3 mt-1 text-black">
        {item.tags?.map((tagObj, index) => (
          <p key={index} className="px-2 bg-blue-400 rounded-full">
            {tagObj.tag}
          </p>
        ))}
      </div>
    </li>
  );
};

export default ScheduleItem;
