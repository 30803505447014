import React from "react";

const Hero = ({ title, subtitle, btnText, btnUrl, bgImg, bgClassName }) => {
  return (
    <div className="relative w-screen">
      {/* Background Image */}

      <img src={bgImg} className="w-full h-full object-cover" />
      <div className="absolute w-full h-full flex flex-col items-center justify-center text-center top-0 bg-gray-950/40">
        <h1 className="font-black text-3xl">{title}</h1>
        <h2 className="font-semibold">{subtitle}</h2>
        <div className="py-3">
          <a href="/catalog">
            <button className="bg-gray-950/70 hover:bg-gray-950/90 px-3 py-2 rounded-md font-bold">
              {btnText}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
