import React, { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import trclogo from "../assets/images/TRC-short-white.png";
import { FaHome, FaStream, FaBook } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import ProfileHeader from "../components/ProfileHeader";
import Footer from "../components/Footer";

const Layout = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Top Header */}
      <header className="bg-gradient-to-b from-slate-950 from-10% to-transparent w-full h-12 text-slate-50 flex items-center px-8 gap-4 fixed top-0 z-50">
        <a href="/catalog">
          <img src={trclogo} alt="logo" className="h-8" />
        </a>
        <ul className="hidden md:flex gap-4 ml-auto items-center">
          <li>
            <Link
              to="/"
              className={`${
                location.pathname === "/" ? "text-red-500" : "text-slate-50"
              } hover:text-red-400`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/stream"
              className={`${
                location.pathname === "/stream"
                  ? "text-red-500"
                  : "text-slate-50"
              } hover:text-red-400 hidden`}
            >
              Live
            </Link>
          </li>
          <li>
            <Link
              to="/catalog"
              className={`${
                location.pathname === "/catalog"
                  ? "text-red-500"
                  : "text-slate-50"
              } hover:text-red-400`}
            >
              Catalog
            </Link>
          </li>
          <li>
            <Link
              to="/profile"
              className={`${
                location.pathname === "/profile"
                  ? "text-red-500"
                  : "text-slate-50"
              } hover:text-red-400 hidden`}
            >
              <ProfileHeader />
            </Link>
          </li>
        </ul>
      </header>

      {/* Bottom Navigation Bar for Small Screens */}
      <nav className="border-t-[2px] border-slate-800 fixed inset-x-0 bottom-0 bg-slate-900 text-slate-50 flex md:hidden justify-around items-center h-16 shadow-lg z-50">
        <Link
          to="/"
          className={`flex flex-col items-center ${
            location.pathname === "/" ? "text-red-500" : "text-slate-50"
          }`}
        >
          <FaHome className="w-6 h-6" />
          <span className="text-sm">Home</span>
        </Link>
        <Link
          to="/stream"
          className={`flex flex-col items-center ${
            location.pathname.startsWith("/stream")
              ? "text-red-500"
              : "text-slate-50"
          } hidden`}
        >
          <FaStream className="w-6 h-6" />
          <span className="text-sm">Live</span>
        </Link>
        <Link
          to="/catalog"
          className={`flex flex-col items-center ${
            location.pathname === "/catalog" ? "text-red-500" : "text-slate-50"
          }`}
        >
          <FaBook className="w-6 h-6" />
          <span className="text-sm">Catalog</span>
        </Link>
        <Link
          to="/profile"
          className={`flex flex-col items-center ${
            location.pathname === "/profile" ? "text-red-500" : "text-slate-50"
          } hidden`}
        >
          <FaRegCircleUser className="w-6 h-6" />
          <span className="text-sm">Profile</span>
        </Link>
      </nav>

      {/* Fullscreen Menu for Small Screens */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-slate-950 bg-opacity-95 z-50 flex flex-col p-8 text-slate-50">
          <button
            className="absolute top-4 right-4 text-white"
            onClick={toggleMenu}
            aria-label="Close Menu"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="flex flex-col gap-8 text-lg">
            <li>
              <Link to="/stream" onClick={toggleMenu}>
                Watch Live
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/catalog" onClick={toggleMenu}>
                Catalog
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleMenu}>
                About us
              </Link>
            </li>
          </ul>
        </div>
      )}

      {/* Main Content */}
      <div className="flex-grow">
        <Outlet />
      </div>

      {/* Footer */}
      <div className="hidden md:inline-block">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
