import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import LoginButton from "./LoginButton";

const ProfileHeader = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <h2>Hi, {user.name}</h2>
        </div>
      ) : (
        <LoginButton />
      )}
    </div>
  );
};

export default ProfileHeader;
