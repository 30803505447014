import PropTypes from "prop-types";
import React from "react";
import CatalogGalleryItem from "./CatalogGalleryItem";

function CatalogGallery({ title, items }) {
  // Ensure items is an array or use an empty array as a fallback
  const safeItems = Array.isArray(items) ? items : [];

  return (
    <div className="my-10 mx-4 p-6 rounded-xl shadow-lg">
      <h1 className="text-white text-3xl font-bold mb-4">{title}</h1>
      <div className="grid gap-6 mt-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        {safeItems.length > 0 ? (
          safeItems.map((item) => (
            <CatalogGalleryItem
              key={item.cdn_id || item.name}
              cdn_id={item.cdn_id}
              title={item.name}
              subtitle={item.subtitle}
              thumbnail={item.thumbnail_file_name}
              date={item.date}
            />
          ))
        ) : (
          <p className="text-gray-500 ml-4">No items found...</p>
        )}
      </div>
    </div>
  );
}

CatalogGallery.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cdn_id: PropTypes.string,
      name: PropTypes.string,
      thumbnail_file_name: PropTypes.string,
      date: PropTypes.string,
    })
  ),
};

CatalogGallery.defaultProps = {
  items: [], // Provide a default empty array for items
};

export default CatalogGallery;
