import React from "react";
import logo from "../assets/images/wbc_letters_transparent.png";

const Footer = () => {
  return (
    <footer className="bg-white text-gray-800 border-t border-gray-200 py-4">
      <div className="container mx-auto flex flex-col items-center">
        {/* Logo */}
        <div className="mb-4">
          <a
            href="https://watersportsbroadcasting.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              alt="Watersports Broadcast Corporation Logo"
              className="h-8"
            />
          </a>
        </div>

        {/* Copyright Text */}
        <div className="text-sm text-center">
          &copy; {new Date().getFullYear()} Watersports Broadcasting
          Corporation. All rights reserved.
        </div>

        {/* Links */}
        <div className="flex gap-4 mt-4 text-sm">
          <a
            href="https://watersportsbroadcasting.com/privacy"
            className="hover:underline"
          >
            Privacy Policy
          </a>
          <a
            href="https://watersportsbroadcasting.com/tos"
            className="hover:underline"
          >
            Terms &amp; Conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
