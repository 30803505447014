import React, { Component } from "react";
import Profile from "../components/Profile";

export class About extends Component {
  render() {
    return <Profile />;
  }
}

export default About;
