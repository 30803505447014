import React, { Component, useEffect, useState } from "react";
import StandardHero from "../components/hero/StandardHero";
import hocrBG from "../assets/images/HOCR_Hero.JPG";
import BackgroundImage from "../assets/images/HOCR_Hero.JPG";
import DroneBG from "../assets/images/hero-drone-fp.jpg";
import RegisterCallToAction from "../components/RegisterCallToAction";
import CatalogRow from "../components/catalog/CatalogRow";
import { supabase } from "../utils/supabase";
import ShowcaseHero from "../components/hero/ShowcaseHero";

function Home() {
  const [featuredItems, setFeaturedItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data: featured, error: featuredError } = await supabase
        .from("catalog")
        .select("cdn_id, name, date, thumbnail_file_name")
        .eq("featured", true)
        .order("date", { ascending: false })
        .limit(20);

      if (featuredError) console.error(featuredError);
      setFeaturedItems(featured);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="text-white md:flex">
        <StandardHero
          title="Watch Live Regattas"
          btnText="Watch Now"
          bgImg={hocrBG}
        />
        <StandardHero
          title="Relive Past Races"
          btnText="Watch Now"
          bgImg={DroneBG}
        />
      </div>
      <div className="w-full h-auto py-8 px-8 flex flex-col bg-gradient-to-r from-indigo-500 to-gray-950">
        <p className="text-gray-200 font-thin">Welcome to the Rowing Channel</p>
        <h1 className="pt-4 text-white font-bold text-2xl">
          Enjoy free access to The Rowing Channel through March 2025
        </h1>
        <p className="text-white font-light pt-2">
          The Rowing Channel is building a platform for the community, by the
          community.
        </p>
      </div>
      {/* <RegisterCallToAction
        title="Start Streaming Today"
        subtitle="No credit card required"
      /> */}
      <ShowcaseHero />
      <CatalogRow title="Featured" items={featuredItems} />
    </>
  );
}

export default Home;
