const buildURL = (videoID, type, customThumbnail) => {
  const baseURL = process.env.REACT_APP_VOD_CDN_URL;
  const defaultFileNames = {
    thumbnail: "thumbnail.jpg",
    preview: "preview.webp",
  };

  const fileName = customThumbnail || defaultFileNames[type];
  if (!fileName) {
    throw new Error(`Unknown type: ${type}`);
  }

  return `${baseURL}/${videoID}/${fileName}`;
};

export default buildURL;
