import React, { useState, useRef, useEffect } from "react";
import bgImg1 from "../../assets/images/cc-rowers-angle.jpg";
import SpecialCoverage from "../../assets/images/special_coverage.jpeg";
import hocr_logo from "../../assets/images/home-icons/hocr.jpg";
import sdcc_logo from "../../assets/images/home-icons/sdcc.png";
import swyc_logo from "../../assets/images/home-icons/swyc.png";
import nwyc_logo from "../../assets/images/home-icons/nwyc.png";
import wira_logo from "../../assets/images/home-icons/wira.png";
import highlight_bg from "../../assets/images/highlights_bg.jpeg";
import wira_start from "../../assets/images/wira_start.jpg";
import OCC_logo from "../../assets/images/home-icons/images.jpeg";
import LBRA_logo from "../../assets/images/home-icons/LBRA-Logo-small-1.jpg";

const LiveEventsContent = () => (
  <div className=" md:flex justify-between w-full">
    <div className=" my-auto h-full max-w-[500px]">
      <h2 className="text-4xl font-bold">Be part of the action</h2>
      <p>
        Catch races at home, from the course, or on the go. Stream live events
        from major regattas across the United States
      </p>
    </div>

    <div className="flex flex-row flex-wrap gap-4 md:max-w-96 justify-center pt-3 md:pt-0">
      <img
        src={hocr_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 rounded-full shadow-md"
      ></img>
      <img
        src={sdcc_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 rounded-full shadow-md"
      ></img>
      <img
        src={swyc_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 rounded-full shadow-md"
      ></img>
      <img
        src={nwyc_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 rounded-full shadow-md"
      ></img>
      <img
        src={wira_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 rounded-full shadow-md"
      ></img>
      <img
        src={OCC_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 rounded-full shadow-md"
      ></img>
      <img
        src={LBRA_logo}
        className="w-20 h-20 lg:w-28 lg:h-28 object-fill rounded-full shadow-md"
      ></img>
    </div>
  </div>
);

const HighlightsContent = () => (
  <div>
    <div className=" max-w-[500px] ">
      <h2 className="text-4xl font-bold">Jump into the action</h2>
      <p className="pt-2">
        Take control of your experience with curated highlights — catch every
        key race, epic crash, and photo-finish moment, all brought to life on
        TRC Live.
      </p>
    </div>
  </div>
);

const InstantReplayContent = () => (
  <div className=" max-w-[500px] ">
    <h2 className="text-4xl font-bold">Feel free to rewind</h2>
    <p className="pt-2">
      Miss a start? You can replay races anytime in stunning Full HD. Missed the
      action? Watch it again, on your terms.
    </p>
  </div>
);

const SpecialCoverageContent = () => (
  <div className=" max-w-[500px] ">
    <h2 className="text-4xl font-bold">Unlock exclusive content</h2>
    <p className="pt-2">
      At every regatta, our team is on the ground capturing the stories that
      make each event unforgettable. With a subscription, gain access to content
      you won’t find anywhere else — experience the regatta like never before.
    </p>
  </div>
);

const tabs = [
  {
    id: 1,
    title: "LIVE EVENTS",
    backgroundImage: bgImg1,
    backgroundPosition: "center",
    content: <LiveEventsContent />,
  },
  {
    id: 2,
    title: "HIGHLIGHTS",
    backgroundImage: highlight_bg,
    backgroundPosition: "center",
    content: <HighlightsContent />,
  },
  {
    id: 3,
    title: "INSTANT REPLAY",
    backgroundImage: wira_start,
    backgroundPosition: "60% 40%",
    content: <InstantReplayContent />,
  },
  {
    id: 4,
    title: "SPECIAL COVERAGE",
    backgroundImage: SpecialCoverage,
    backgroundPosition: "65% 35%",
    content: <SpecialCoverageContent />,
  },
];

const ShowcaseHero = () => {
  const [activeTab, setActiveTab] = useState(0); // Initialize with index 0
  const [underlineStyle, setUnderlineStyle] = useState({ left: 0, width: 0 });
  const tabRefs = useRef([]);

  useEffect(() => {
    const currentTab = tabRefs.current[activeTab];
    if (currentTab) {
      setUnderlineStyle({
        left: currentTab.offsetLeft,
        width: currentTab.clientWidth,
      });
    }
    const preloadImages = () => {
      tabs.forEach((tab) => {
        const img = new Image();
        img.src = tab.backgroundImage;
      });
    };

    preloadImages();
  }, [activeTab]);

  return (
    <div
      className=" h-fit flex py-8"
      style={{
        backgroundColor: "#4A3219", // Fallback color similar to your image tones
        backgroundImage: `
      linear-gradient(rgba(74, 50, 25, 0.75), rgba(74, 50, 25, 0.5)), 
      url(${tabs[activeTab].backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: tabs[activeTab].backgroundPosition,
      }}
    >
      <div className="mx-auto container flex flex-col lg:justify-top w-full text-white px-4 z-10 min-h-96">
        <div className="relative">
          <div className="flex flex-row gap-4 font-thin text-xs py-2">
            {tabs.map((tab, index) => (
              <button
                key={tab.id}
                ref={(el) => (tabRefs.current[index] = el)}
                className={`${
                  activeTab === index
                    ? "text-white"
                    : "text-gray-300 hover:text-white"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <span
            className="absolute bottom-0 h-0.5 bg-white transition-all duration-300"
            style={{ left: underlineStyle.left, width: underlineStyle.width }}
          />
        </div>
        <div className="my-auto">{tabs[activeTab].content}</div>
      </div>
    </div>
  );
};

export default ShowcaseHero;
