import PropTypes from "prop-types";
import React from "react";
import CatalogItem from "./CatalogItem";

function CatalogRow({ title, items }) {
  // Ensure items is an array or use an empty array as a fallback
  const safeItems = Array.isArray(items) ? items : [];

  return (
    <div className="my-10 pl-6 rounded-xl shadow-lg">
      <h1 className="text-white text-3xl font-bold mb-4">{title}</h1>
      <div className="flex gap-6 mt-4 overflow-x-auto no-scrollbar p-2">
        {safeItems.length > 0 ? (
          safeItems.map((item) => (
            <CatalogItem
              key={item.cdn_id || item.name}
              cdn_id={item.cdn_id}
              title={item.name}
              subtitle={item.subtitle}
              thumbnail={item.thumbnail_file_name}
              date={item.date}
            />
          ))
        ) : (
          <p className="text-gray-500 ml-4">No items found...</p>
        )}
      </div>
    </div>
  );
}

CatalogRow.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cdn_id: PropTypes.string,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      thumbnail_file_name: PropTypes.string,
      date: PropTypes.string,
    })
  ),
};

CatalogRow.defaultProps = {
  items: [],
};

export default CatalogRow;
